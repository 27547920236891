import { Face } from "@mui/icons-material";
import { ImageCarousel } from "Pages";

const ImageCarouselContent = {
  title: "",
  images: [
    {
      objectPosition: "-80px  top",
      src: "https://images.squarespace-cdn.com/content/v1/5332024be4b0bd216afed29e/1463784125405-PYVC5BYFZNF130I48H7N/11082256_626319367469576_1954232739155492820_o.jpg?format=2500w",
    },
    {
      objectPosition: "-120px top",
      src: "https://images.squarespace-cdn.com/content/v1/5332024be4b0bd216afed29e/1463784152381-UD8KBWYGE5MSZZ2977BU/10451706_10152815868084288_5199377760708688948_n.jpg?format=2500w",
    },
    {
      objectPosition: "right top",
      src: "https://images.squarespace-cdn.com/content/v1/5332024be4b0bd216afed29e/1505244982083-7EP73P62M95392K7OU3G/19510466_1595127587184277_512181719137668407_n.jpg?format=1500w",
    },
    {
      objectPosition: "-200px",
      src: "https://images.squarespace-cdn.com/content/v1/5332024be4b0bd216afed29e/1463840529053-5KOR58W9IFUXQZB5DP7A/Monkier+Promo+1.jpg?format=2500w",
    },
    {
      objectPosition: "-240px top",
      src: "https://images.squarespace-cdn.com/content/v1/5332024be4b0bd216afed29e/1467148438882-J1SSYN6CPPUG1WO7YBPP/Monkier+Athfest.jpeg?format=2500w",
    },
  ],
  icon: <Face />,
  path: "/home",
  home: true,
  hidden: false,
  menu: false,
  menuIndex: 10,
  element: <ImageCarousel />,
};

export default ImageCarouselContent;
