import { Contact } from "Pages";
import { Email } from "@mui/icons-material";

const ContactContent = {
  title: "Contact",
  message:
    "Thanks for stopping by! Please feel free to reach out using the form below.",
  sendToEmail: process.env.REACT_APP_EMAIL,
  icon: <Email />,
  path: "/contact",
  hidden: false,
  menu: true,
  menuIndex: 4,
  element: <Contact />,
};

export default ContactContent;
