import { Header } from "Components";

const HeaderContent = {
  title: "Zac Evans",
  subtitle: "musician | composer | educator | misc.",
  showGifs: true,
  element: <Header />,

  // Update fontSize, fontFamily, fontWeight, color, etc.
  titleStyle: { fontFamily: "Kanit, sans-serif", fontWeight: "500" },
  subTitleStyle: { fontWeight: "300", fontSize: "1.4rem" },
  navigationStyle: {
    // fontFamily: "Kanit, sans-serif",
    fontWeight: "500",
    fontSize: "1rem",
  },
  // menuStyle: { fontWeight: "400", fontSize: "1rem" },
};

export default HeaderContent;
