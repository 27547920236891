import { useState } from "react";
import { ImageList, ImageListItem, Dialog, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useIsMobile } from "Hooks";
import { LoadingIndicator } from "Components";
import { motion } from "framer-motion";

const Photos = ({ getImages }) => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const handleImageClick = (e) => {
    const { src, alt, clientHeight, clientWidth } = e.target;

    const orientation =
      Math.abs(clientHeight - clientWidth) <= 50
        ? "square"
        : clientHeight > clientWidth
        ? "portrait"
        : "landscape";
    setSelectedImage({
      src,
      orientation,
      alt,
    });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const { data, isLoading } = useQuery(["photos"], getImages);

  const images = data?.Contents?.map((item) => item)
    .slice(1)
    .filter(
      (item) => !item.Key.includes("bio") && !item.Key.includes("teaching")
    );

  return (
    <motion.div
      style={{ opacity: 0, height: "100%" }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Grid
        container
        justifyContent='center'
        sx={{ paddingTop: "10px", height: "100%" }}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <ImageList
            variant='masonry'
            cols={isMobile ? 1 : 3}
            gap={8}
            sx={{ overflow: "visible", marginTop: 0 }}
          >
            {images?.map((item) => (
              <ImageListItem key={item.ETag}>
                <img
                  src={`https://luke-website.s3.amazonaws.com/${item.Key}`}
                  alt={item.Key}
                  onClick={isMobile ? null : (e) => handleImageClick(e)}
                  style={{ cursor: isMobile ? "default" : "pointer" }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
        <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
          <img
            src={selectedImage?.src}
            style={
              selectedImage.orientation === "landscape"
                ? {
                    width: "70vw",
                    maxHeight: "95vh",
                    objectFit: "cover",
                    overflow: "hidden",
                  }
                : selectedImage.orientation === "portrait"
                ? {
                    height: "70vh",
                    maxWidth: "95vw",
                    objectFit: "cover",
                    overflow: "hidden",
                  }
                : {
                    width: "50vw",
                    objectFit: "cover",
                    overflow: "hidden",
                  }
            }
            alt={selectedImage?.alt}
          />
        </Dialog>
      </Grid>
    </motion.div>
  );
};

export default Photos;
