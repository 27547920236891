import { useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { useIsMobile } from "Hooks";
import { motion } from "framer-motion";

const About = ({ paragraph, image, bioName, imageAspectRatio }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const bioLeft = paragraph.split(bioName)[0];
  const bioRight = paragraph.split(bioName)[1];

  return (
    <motion.div
      style={{
        opacity: 0,
        height: isMobile ? "auto" : "100%",
      }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Grid container sx={{ paddingTop: "10px" }}>
        <div>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: isXS ? "flex" : "block",
              justifyContent: isMobile ? "center" : null,
              width: "100%",
            }}
          >
            <img
              src={image.src}
              alt='about-pic'
              style={{
                float: isXS ? "none" : "left",
                margin: isXS ? "0px 0px 15px 0px" : "5px 25px 10px 0px",
                display: imageLoaded ? "block" : "none",
              }}
              onLoad={handleImageLoaded}
            />
            {!imageLoaded && (
              <Skeleton
                variant='rectangular'
                width='100%'
                height='auto'
                sx={{
                  float: isXS ? "none" : "left",
                  margin: isXS ? "0px 0px 15px 0px" : "5px 25px 10px 0px",
                  aspectRatio: imageAspectRatio,
                }}
              />
            )}
          </Grid>
          <Typography
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {bioLeft}
            <b>{bioName}</b>
            {bioRight}
          </Typography>
        </div>
      </Grid>
    </motion.div>
  );
};

export default About;
