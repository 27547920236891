import { OndemandVideo } from "@mui/icons-material";
import { Videos } from "Pages";

const VideosContent = {
  title: "Videos",
  playlistID: "PLzWSYdoDNFvYOCu5_y1WBAnBt_zdxytAX",
  icon: <OndemandVideo />,
  path: "/videos",
  hidden: false,
  // subMenu: false,
  menu: true,
  menuIndex: 2,
  element: <Videos />,
};
export default VideosContent;
