import { Face } from "@mui/icons-material";
import { About } from "Pages";

const AboutContent = {
  title: "About",
  paragraph:
    'Zac Evans is a composer, multi-instrumentalist, and educator currently based in San Antonio, TX. As a composer, Zac has had the opportunity to write for a diverse range of ensembles ranging from jazz big bands, to live hip-hop. He leads the hip-hop/jazz outfit Monkier which was named "Critic\'s Pick - Best Jazz Act of 2016" in Creative Loafing Atlanta after the release of their sophomore album, “Highs/Lows,” co-written and produced by Zac. Monkier has had the opportunity to open for some notable national acts such as Knower, Moon Hooch, Naughty Professor, Dynamo, and Moonchild. Zac also co-leads the Rebel Big Band, a collective ensemble dedicated to original and progressive music for jazz big band, which released their self-titled debut album in 2019. \n\n As a performer, Zac has had the opportunity to perform and record with a wide variety of local artists and ensembles including the Georgia Symphony Orchestra, the Macon Pops, City Springs Theater Company, the Atlanta Lyric Theater, Michael Feinstein, Sam Burchfield, Blair Crimmins, the Video Game Alliance, the Atlanta Latin Jazz Orchestra, and many others. Zac also had the opportunity to appear on screen in Dolly Parton\'s Netflix series "Heartstrings" as a background musician. \n\n From 2016-2021 Zac taught applied saxophone at Perimeter College in Atlanta, GA. For the past decade, he has also maintained an active private studio and is passionate about educating musicians of all levels. In 2013 he earned his BM in Saxophone Performance from Kennesaw State University under the tutelage of Sam Skelton. In 2016, Zac received his MM in Jazz Composition from the University of Texas at Austin.',
  bioName: "Zac Evans",
  image: {
    src: "https://images.squarespace-cdn.com/content/v1/5332024be4b0bd216afed29e/1463786891501-83QXIVAE3CIEYGVKMV31/image-asset.jpeg?format=1500w",
  },
  imageAspectRatio: "4/3",
  icon: <Face />,
  path: "/about",
  home: false,
  hidden: false,
  menu: true,
  menuIndex: 1,
  element: <About />,
};

export default AboutContent;
