import { Typography, MenuItem, useTheme } from "@mui/material";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

const DesktopNavigation = ({
  navigation,
  navigationStyle,
  menuStyle,
  menuProps,
  navigationTypographyProps,
}) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { pathname } = useLocation();
  console.log(navigation);

  return navigation.map(({ path, title, children }) => (
    <div key={uuid()}>
      <PopupState variant='popover' popupId={uuid()}>
        {(popupState) => (
          <>
            <Typography
              {...bindHover(popupState)}
              variant='body2'
              sx={{
                "&:hover": {
                  color: palette.primary.main,
                },
                ...navigationStyle,
                ...(pathname === path ||
                children?.some((child) => pathname === child.path)
                  ? { color: palette.primary.main }
                  : {}),
              }}
              onClick={() => !children?.length && navigate(path)}
              {...navigationTypographyProps}
            >
              {title?.toUpperCase()}
            </Typography>
            {children?.length && (
              <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {children?.map((menuItem) => (
                  <MenuItem
                    onClick={() =>
                      menuItem?.href
                        ? window.open(menuItem?.href, "_blank")
                        : navigate(path)
                    }
                    key={menuItem?.path}
                    divider
                    sx={{
                      "&:hover": {
                        color: palette.primary.main,
                      },
                      ...menuStyle,
                      ...(pathname === menuItem?.path
                        ? { color: palette.primary.main }
                        : {}),
                    }}
                    {...menuProps}
                  >
                    {menuItem?.title?.toUpperCase()}
                  </MenuItem>
                ))}
              </HoverPopover>
            )}
          </>
        )}
      </PopupState>
    </div>
  ));
};

export default DesktopNavigation;

DesktopNavigation.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.element,
      element: PropTypes.element,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          href: PropTypes.string,
          path: PropTypes.string,
          icon: PropTypes.element,
          element: PropTypes.element,
        })
      ),
    })
  ),
  navigationStyle: PropTypes.object,
  menuStyle: PropTypes.object,
  menuProps: PropTypes.object,
  navigationTypographyProps: PropTypes.object,
};
