import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const defaultProps = {
  title: "Site Title",
  subtitle: "Site Subtitle",
  navigation: [],
  titleStyle: {},
  subTitleStyle: {},
  navigationStyle: {},
  menuStyle: {},
};

const Header = ({
  navigation,
  title,
  subtitle,
  titleStyle,
  subTitleStyle,
  navigationStyle,
  menuStyle,
  menuProps,
  navigationTypographyProps,
  titleTypographyProps,
  subTitleTypographyProps,
  listItemProps,
  listItemButtonProps,
  listItemIconProps,
  listItemTextProps,
  listProps,
  showGifs,
}) => {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { pathname } = useLocation() || {};
  const homePath = navigation?.find((item) => item.home)?.path;
  console.log(navigation);

  const currentPageObject = navigation?.find((item) => {
    if (item.children) {
      return item.children.find((child) => child?.path === pathname);
    }
    return item.path === pathname;
  });

  const currentPageName = currentPageObject?.children
    ? currentPageObject.children?.find((child) => child?.path === pathname)
        ?.title
    : currentPageObject?.title;

  const filteredNavigation = navigation?.filter((item) => item.menu);

  return (
    <motion.div
      style={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Grid container sx={{ zIndex: 100, marginBottom: isMobile ? 0 : "0px" }}>
        <Grid
          item
          xs={12}
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Typography
            variant='h2'
            fontWeight='bold'
            sx={{
              color: palette.text.primary,
              "&:hover": { color: palette.primary.main },
              paddingTop: ".5rem",
              textAlign: "center",
              fontSize: "clamp(1rem, 10vw, 4rem)",
              marginX: "4rem",
              cursor: "pointer",
              ...titleStyle,
            }}
            onClick={() => navigate(homePath)}
            {...titleTypographyProps}
          >
            {title.toUpperCase()}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              textAlign: "center",
              fontSize: "clamp(1rem, 5vw, 1.5rem)",
              ...subTitleStyle,
            }}
            color='text.secondary'
            {...subTitleTypographyProps}
          >
            {subtitle.toLowerCase()}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isMobile ? (
            <MobileNavigation
              navigation={filteredNavigation}
              listItemProps={listItemProps}
              listItemButtonProps={listItemButtonProps}
              listItemIconProps={listItemIconProps}
              listItemTextProps={listItemTextProps}
              listProps={listProps}
              showGifs={showGifs}
            />
          ) : (
            <DesktopNavigation
              navigation={filteredNavigation}
              navigationStyle={{
                paddingX: "2rem",
                marginTop: "2rem",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "18px",
                ...navigationStyle,
              }}
              menuStyle={{ fontSize: "16px", fontWeight: "bold", ...menuStyle }}
              menuProps={menuProps}
              navigationTypographyProps={navigationTypographyProps}
            />
          )}
        </Grid>
        <AnimatePresence mode='popLayout'>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: currentPageName ? 1 : 0 }}
            exit={{ x: -150, opacity: 0 }}
            transition={{ duration: 0.7 }}
            key={currentPageName}
            style={{ height: "42px", minWidth: "100px" }}
          >
            <Typography variant='h4' fontWeight='bold' color='primary'>
              {currentPageName}
            </Typography>
          </motion.div>
        </AnimatePresence>
        <Divider
          sx={{
            marginBottom: "10px",
            width: "100%",
          }}
        />
      </Grid>
    </motion.div>
  );
};

export default Header;

Header.defaultProps = defaultProps;

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.element,
      element: PropTypes.element,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          href: PropTypes.string,
          path: PropTypes.string,
          icon: PropTypes.element,
          element: PropTypes.element,
        })
      ),
    })
  ),
  titleStyle: PropTypes.object,
  subTitleStyle: PropTypes.object,
  navigationStyle: PropTypes.object,
  menuStyle: PropTypes.object,
};
