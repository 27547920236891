import axios from "axios";
import { S3Client as s3 } from "@aws-sdk/client-s3";

export const getYouTubePlaylist = (playlistID) =>
  axios.get(
    `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
  );

export const S3Client = new s3({
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});
