const light = {
  palette: {
    primary: {
      main: "#e60215",
      dark: "#1f5985",
    },
    secondary: {
      main: "#e60215",
    },
    background: {
      default: "#fbfbfb",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
};

const dark = {
  palette: {
    primary: {
      main: "#e60215",
      dark: "#1f5985",
    },
    secondary: {
      main: "#e60215",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
};

const themes = { light, dark };

export default themes;
