import { motion } from "framer-motion";
import { Spotify } from "react-spotify-embed";

const Music = ({ spotifyPlaylistURL }) => {
  return (
    <motion.div
      style={{ opacity: 0, height: "100%" }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Spotify
        link={spotifyPlaylistURL}
        height='100%'
        style={{ height: "100%", backgroundColor: "#FFFFFF" }}
        wide
      />
    </motion.div>
  );
};

export default Music;
