import { fetchAWSBucketImages } from "Utils";
import { CameraAlt } from "@mui/icons-material";
import { Photos } from "Pages";

const params = {
  Bucket: "luke-website",
  Prefix: "images/",
};

const PhotosContent = {
  title: "Photos",
  getImages: () => fetchAWSBucketImages(params),
  icon: <CameraAlt />,
  path: "/photos",
  hidden: true,
  subMenu: false,
  menuIndex: 10,
  element: <Photos />,
};

export default PhotosContent;
