import * as Content from "Content";
import { cloneElement } from "react";

const navigation = Object.entries(Content)
  ?.filter(
    ([key, value]) =>
      !value.hidden && (value.menu || value.subMenu || value.home)
  )
  ?.map(([key, value]) => {
    console.log(value);
    if (value.children) {
      // if the content has children, map over them and add the element
      const children = (value.children = value.children.map((child) => ({
        ...child,
        element: child.element ? cloneElement(child.element, child) : null,
      })))?.sort((a, b) => a.menuIndex - b.menuIndex);

      return { ...value, children };
    }
    return {
      ...value,
      element: value.element ? cloneElement(value.element, value) : null,
    };
  })
  ?.sort((a, b) => a.menuIndex - b.menuIndex);

export default navigation;
