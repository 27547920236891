import { PermMedia } from "@mui/icons-material";
import Music from "./Music";
// import Photos from "./Photos";
import Videos from "./Videos";

const MediaContent = {
  title: "Media",
  children: [Videos, Music],
  hidden: true,
  menu: false,
  icon: <PermMedia />,
  menuIndex: 1,
};

export default MediaContent;
