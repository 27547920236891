import { PermMedia, TheaterComedy, Nightlife } from "@mui/icons-material";

const MediaContent = {
  title: "Projects",
  children: [
    {
      href: "https://whoismonkier.com",
      title: "Monkier",
      icon: <TheaterComedy />,
    },
    {
      href: "https://www.facebook.com/rebelbigband/",
      title: "Rebel Big Band",
      icon: <Nightlife />,
    },
  ],
  hidden: false,
  menu: true,
  icon: <PermMedia />,
  menuIndex: 3,
};

export default MediaContent;
