import { School } from "@mui/icons-material";
import { Teaching } from "Pages";

const TeachingContent = {
  title: "Teaching",
  paragraph:
    "Private lessons are the quickest way to to make rapid improvement as a musician. I have over 10 years of teaching experience, working with students at a beginner level, all the way up through college level. I have a passion for helping my students achieve their highest while also finding the joy in playing music. I offer lessons both in-person and virtually through Zoom. \n\n My current rates are $40 per 30-minute lesson, or $70 per 60-minute lesson.",
  image: {
    src: "https://luke-website.s3.amazonaws.com/images/teaching-photo.webp",
  },
  imageAspectRatio: "4/3",
  headline: "Lessons with Luke",
  contactSubjectLine: "Lesson Inquiry",
  contactMessage: "Hi! I'm interested in booking a lesson.",
  icon: <School />,
  path: "/teaching",
  hidden: true,
  menu: false,
  menuIndex: 10,
  element: <Teaching />,
};

export default TeachingContent;
