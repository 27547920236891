import { useState, Fragment } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Collapse,
  useTheme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {
  saxMan,
  sadClarinet,
  penguinFlute,
  saxDude,
  saxMan2,
  animeSax1,
  animeSax2,
} from "images";

const MobileNavigation = ({
  navigation,
  listItemProps,
  listItemButtonProps,
  listItemIconProps,
  listItemTextProps,
  listProps,
  showGifs,
}) => {
  const randomKey = Math.floor(Math.random() * 7);

  const navigate = useNavigate();
  const { palette } = useTheme();
  const { pathname } = useLocation();

  const subMenusInitialState = navigation
    .filter((item) => item.children)
    .reduce((a, v) => {
      let expanded = false;
      if (v.children.some((child) => pathname === child.path)) {
        expanded = true;
      }
      return { ...a, [v.title]: expanded };
    }, {});

  const [open, setOpen] = useState(false);
  const [subMenuState, setSubMenuState] = useState(subMenusInitialState);

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpen(false);
  };

  const gifs = [
    {
      key: 0,
      gif: saxMan,
      alt: "sax-man",
      href: "https://youtu.be/gy1B3agGNxw?t=41",
    },
    {
      key: 1,
      gif: sadClarinet,
      alt: "sad-clarinet",
      href: "https://www.youtube.com/watch?v=Ti1gbVE0cWw",
    },
    {
      key: 2,
      gif: penguinFlute,
      alt: "penguin-flute",
      href: "https://www.youtube.com/watch?v=_LUTZhvUGQI",
    },
    {
      day: 3,
      gif: saxDude,
      alt: "sax-dude",
      href: "https://www.youtube.com/watch?v=CRatTuWdT_Q",
    },
    {
      key: 4,
      gif: saxMan2,
      alt: "sax-dude",
      href: "https://www.youtube.com/watch?v=ed6XctFHKd8",
    },
    {
      key: 5,
      gif: animeSax1,
      alt: "sax-dude",
      href: "https://www.youtube.com/watch?v=jRHJntLYMh0",
    },
    {
      key: 6,
      gif: animeSax2,
      alt: "sax-dude",
      href: "https://youtu.be/3fY-ja0q1fw?t=125",
    },
  ];

  const todaysGif = gifs.find((gif) => gif.key === randomKey) || gifs[0];

  const list = () => (
    <Box
      sx={{
        width: 250,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        padding: 0,
      }}
      role='presentation'
    >
      <List {...listProps}>
        {navigation.map(({ title, path, icon, children }, index) =>
          children?.length ? (
            <Fragment key={index}>
              <ListItemButton
                onClick={() =>
                  setSubMenuState({
                    ...subMenuState,
                    [title]: !subMenuState[title],
                  })
                }
                {...listItemButtonProps}
              >
                <ListItemIcon {...listItemIconProps}>{icon}</ListItemIcon>
                <ListItemText primary={title} {...listItemTextProps} />
                {subMenuState[title] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={subMenuState[title]} timeout='auto' unmountOnExit>
                <List component='div' disablePadding {...listProps}>
                  {children.map((child) => (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() =>
                        child?.href
                          ? window.open(child?.href, "_blank")
                          : handleNavigation(child?.path)
                      }
                      key={child?.path}
                      {...listItemButtonProps}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            child?.path === pathname
                              ? palette.primary.main
                              : null,
                        }}
                        {...listItemIconProps}
                      >
                        {child?.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={child?.title}
                        primaryTypographyProps={{
                          color:
                            child?.path === pathname
                              ? palette.primary.main
                              : null,
                          fontWeight: child?.path === pathname ? "bold" : null,
                        }}
                        {...listItemTextProps}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ) : (
            <ListItem key={path} disablePadding {...listItemProps}>
              <ListItemButton
                onClick={() => handleNavigation(path)}
                {...listItemButtonProps}
              >
                <ListItemIcon
                  sx={{
                    color: path === pathname ? palette.primary.main : null,
                  }}
                  {...listItemIconProps}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    color: path === pathname ? palette.primary.main : null,
                    fontWeight: path === pathname ? "bold" : null,
                  }}
                  {...listItemTextProps}
                />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      {showGifs && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <a href={todaysGif.href} target='_blank' rel='noreferrer'>
            <img
              src={todaysGif.gif}
              alt={todaysGif.alt}
              style={{ height: "50px", marginBottom: "-6px" }}
            />
          </a>
        </div>
      )}
    </Box>
  );

  return (
    <>
      <MenuIcon
        fontSize='large'
        sx={{ position: "absolute", top: 10, left: 10 }}
        onClick={() => setOpen(!open)}
      />
      <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

export default MobileNavigation;

MobileNavigation.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.element,
      element: PropTypes.element,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          path: PropTypes.string,
          icon: PropTypes.element,
          element: PropTypes.element,
        })
      ),
    })
  ),
  listItemProps: PropTypes.object,
  listItemButtonProps: PropTypes.object,
  listItemIconProps: PropTypes.object,
  listItemTextProps: PropTypes.object,
  listProps: PropTypes.object,
  showGifs: PropTypes.bool,
};
