import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { useIsMobile } from "Hooks";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ImageCarousel = ({ images }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <motion.div
      style={{
        opacity: 0,
        height: isMobile ? "auto" : "100%",
        width: "100%",
      }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Grid container sx={{ paddingTop: "10px", width: "100%" }}>
        <Carousel
          showThumbs={false}
          height='80vh'
          autoPlay
          infiniteLoop
          interval={5000}
          transitionTime={1000}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
        >
          {images.map((item) => (
            <img
              src={item.src}
              key={item.name}
              alt={item.name}
              style={{
                maxHeight: isXS ? undefined : "calc(100vh - 320px)",
                height: isXS ? "calc(100vh - 250px)" : "auto",
                width: "100%",
                objectFit: "cover",
                objectPosition: isXS ? item.objectPosition : undefined,
              }}
            />
          ))}
        </Carousel>
      </Grid>
    </motion.div>
  );
};

export default ImageCarousel;
