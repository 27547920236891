import { ListObjectsV2Command } from "@aws-sdk/client-s3";
import { S3Client } from "Api";

const fetchAWSBucketImages = async (params) => {
  const command = new ListObjectsV2Command(params);

  try {
    const data = await S3Client.send(command);
    return data;
  } catch (err) {
    console.error(err);
  }
};

export default fetchAWSBucketImages;
