import { CircularProgress } from "@mui/material";

const LoadingIndicator = () => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-50px",
      }}
    >
      <CircularProgress size={100} />
    </div>
  );
};

export default LoadingIndicator;
