import { Grid } from "@mui/material";
import YouTubeViewer from "Components/YouTubeViewer";
import { motion } from "framer-motion";
import { useIsMobile } from "Hooks";

const Videos = ({ playlistID }) => {
  const isMobile = useIsMobile();
  return (
    <motion.div
      style={{ opacity: 0, height: isMobile ? "auto" : "100%" }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Grid
        container
        sx={{ width: "100%", paddingTop: "10px", height: "100%" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <YouTubeViewer playlistID={playlistID} />
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default Videos;
