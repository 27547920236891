import { Typography, useTheme } from "@mui/material";
import { useIsMobile } from "Hooks";

const Footer = () => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "10px",
        paddingTop: "25px",
        flexDirection: "column",
      }}
    >
      {isMobile ? (
        <>
          <Typography variant='caption' color='text.secondary'>
            All Rights Reserved. Zac Evans Music ©{new Date().getFullYear()} |{" "}
            <a
              href='https://zacevanscoding.com'
              target='_blank'
              rel='noreferrer'
              style={{ color: palette.primary.main }}
            >
              Design
            </a>
          </Typography>
        </>
      ) : (
        <Typography variant='caption' color='text.secondary'>
          All Rights Reserved. Zac Evans Music ©{new Date().getFullYear()} |{" "}
          <a
            href='https://zacevanscoding.com'
            target='_blank'
            rel='noreferrer'
            style={{ color: palette.primary.main }}
          >
            Design
          </a>
        </Typography>
      )}
    </div>
  );
};

export default Footer;
