import { useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "Hooks";
import { motion } from "framer-motion";

const Teaching = ({
  paragraph,
  image,
  headline,
  contactSubjectLine,
  contactMessage,
  imageAspectRatio,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <motion.div
      style={{
        opacity: 0,
        height: "100%",
      }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Grid container sx={{ paddingTop: "10px" }} alignItems='center'>
        <div>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: isXS ? "flex" : "block",
              justifyContent: isMobile ? "center" : null,
              width: "100%",
            }}
          >
            <img
              src={image.src}
              alt='Teaching-pic'
              style={{
                float: isXS ? "none" : "left",
                margin: isXS ? "0px 0px 15px 0px" : "5px 25px 10px 0px",
                display: imageLoaded ? "block" : "none",
              }}
              onLoad={handleImageLoaded}
            />
            {!imageLoaded && (
              <Skeleton
                variant='rectangular'
                width='100%'
                height='auto'
                sx={{
                  float: isXS ? "none" : "left",
                  margin: isXS ? "0px 0px 15px 0px" : "5px 25px 10px 0px",
                  aspectRatio: imageAspectRatio,
                }}
              />
            )}
          </Grid>
          <Typography
            variant='h5'
            fontWeight='bold'
            align='center'
            sx={{ paddingY: "10px" }}
          >
            {headline}
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "0px 0px 15px 0px",
            }}
          >
            <Button
              variant='contained'
              size='large'
              fullWidth
              sx={{ fontWeight: "bold" }}
              onClick={() =>
                navigate("/contact", {
                  state: {
                    formValues: {
                      subject: contactSubjectLine,
                      message: contactMessage,
                    },
                  },
                })
              }
            >
              Book a Lesson
            </Button>
          </Grid>

          <Typography
            sx={{
              whiteSpace: "pre-line",
              textAlign: "justify",
              paddingBottom: "15px",
            }}
          >
            {paragraph}
          </Typography>
        </div>
      </Grid>
    </motion.div>
  );
};

export default Teaching;
